import Container from 'components/layout/Container';
import React, { useState } from 'react';
import Link from 'components/Link';
import theme from 'utils/theme';
import { NavColumn } from '../types';
import {
  DesktopOnly,
  Wrapper,
  Columns,
  Column,
  Underlay,
  TabsRow,
  ContentRow,
  TabsFlexRow,
} from './styles';
import { MegaNavDesktopProps, MegaNavDesktopContentProps } from './types';

function DesktopColumn({ column }: { column: NavColumn }) {
  const titleEl = column.path ? (
    <Link variant="secondary" href={column.path}>
      <strong>{column.title}</strong>
    </Link>
  ) : (
    <strong>{column.title}</strong>
  );

  return (
    <Column>
      {titleEl}
      <ul>
        {column.links?.map((link) => (
          <li key={link._key}>
            <Link href={link.path} variant="secondary">
              {link.title}
            </Link>
          </li>
        ))}
        {column.path ? (
          <li>
            <Link href={column.path} variant="secondary">
              View All
            </Link>
          </li>
        ) : null}
      </ul>
    </Column>
  );
}

function MegaNavDesktopContent({ section }: MegaNavDesktopContentProps) {
  const [selectedTab, setSelectedTab] = useState(section.subItems?.[0]);

  return (
    <>
      {section.subItems && section.subItems.length > 1 ? (
        <TabsRow>
          <Container>
            <TabsFlexRow>
              {section.subItems?.map((tab) => (
                <Link
                  variant="secondary"
                  key={tab._key}
                  href={tab.path || '#'}
                  active={selectedTab === tab}
                  onMouseOver={() => setSelectedTab(tab)}
                >
                  {tab.title}
                </Link>
              ))}
            </TabsFlexRow>
          </Container>
        </TabsRow>
      ) : null}
      <ContentRow>
        <Container>
          <Columns>
            {selectedTab?.subItems?.map((column) => (
              <DesktopColumn column={column} key={column._key} />
            ))}
          </Columns>
        </Container>
      </ContentRow>
    </>
  );
}

export default function MegaNavDesktop({
  section,
  onClose,
}: MegaNavDesktopProps) {
  return (
    <DesktopOnly>
      {section.subItems?.length ? (
        <Wrapper
          initial={{ opacity: 0, translateY: -20 }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: 'easeOut',
            duration: theme.transitionSpeeds.fast,
          }}
        >
          <MegaNavDesktopContent key={section._key} section={section} />
        </Wrapper>
      ) : null}
      <Underlay
        onMouseOver={onClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          ease: 'easeOut',
          duration: theme.transitionSpeeds.slow,
        }}
      />
    </DesktopOnly>
  );
}
