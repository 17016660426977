import { motion } from 'framer-motion';
import styled from 'styled-components';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

export const DesktopOnly = styled.div`
  display: none;

  ${desktop} {
    display: block;
  }
`;

export const Wrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  z-index: ${theme.zIndexes.header};
  background-color: ${theme.colors.offWhite};
`;

export const Underlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.modalBackground};
  z-index: ${theme.zIndexes.mid};
`;

export const TabsRow = styled.div`
  border-top: 2px solid ${theme.colors.offWhite};
  background-color: ${theme.colors.white};
  padding: 20px 0;

  a {
    font-weight: ${theme.weights.bold};
    margin-right: 30px;
  }
`;

export const TabsFlexRow = styled.div`
  display: flex;
  margin-left: 200px;
`;

export const ContentRow = styled.div`
  padding: 20px 0;
`;

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 180px;
  max-width: 900px;
`;

export const Column = styled.div`
  width: 180px;
  margin: 20px;

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;
