import styled from 'styled-components';
import theme from 'utils/theme';
import React from 'react';

const Wrapper = styled.div({
  background: theme.colors.lightGreen,
  textAlign: 'center',
  padding: 10,
  fontSize: 14,
  zIndex: theme.zIndexes.header,
  position: 'relative',
});

interface HeaderAlertProps {
  alert: string;
}

export default function HeaderAlert({ alert }: HeaderAlertProps) {
  return <Wrapper>{alert}</Wrapper>;
}
