import styled from 'styled-components';

interface ContainerProps {
  noGutter?: boolean;
  noPadding?: boolean;
  inset?: boolean;
}

const Container = styled.div<ContainerProps>((props) => ({
  position: 'relative',
  maxWidth: '100%',
  margin: '0 auto',
  ...(props.noGutter
    ? {}
    : {
        width: props.inset ? 1100 : 1320,
        padding: props.noPadding ? 0 : '0 20px',
      }),
}));

export default Container;
