import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetSupplementalProductsDocument,
  GetSupplementalProductsQuery,
  GetSupplementalProductsQueryVariables,
  LineItemDataFragment,
  ProductAvailabilityDataFragment,
  SupplementalProductVariantDataFragment,
} from 'generated/api/graphql';
import { chain, compact, find, isArray, last, map } from 'lodash';
import * as Sentry from '@sentry/nextjs';

type ProductUrlArgs = Pick<
  Sproutl.ProductVariant,
  'sku' | 'slug' | 'categories' | 'attributesRaw'
>;

export function getProductUrl({
  sku,
  slug,
  categories,
  attributesRaw,
}: ProductUrlArgs): string {
  let categorySlug = 'products';

  if (categories && isArray(categories)) {
    categorySlug = last(categories)?.slug || categorySlug;
  }

  if (attributesRaw) {
    categorySlug =
      find(attributesRaw, ({ name }) => name === 'category_slug')?.value ||
      categorySlug;
  }

  return `/${categorySlug}${categorySlug ? '/' : ''}${slug}-${sku}`;
}

export function getOffers(
  variant: ProductAvailabilityDataFragment,
): Sproutl.ProductOffer[] {
  const offers: Sproutl.ProductOffer[] = [];

  variant?.availability?.channels.results.forEach((channel) => {
    const price = variant?.prices?.find(
      (priceOption) => priceOption?.channel?.key === channel?.channel?.key,
    );

    if (channel.channel?.name && channel.channel.key && price) {
      offers.push({
        partner: {
          name: channel.channel.name,
          slug: channel.channel.key,
        },
        price: price.value,
        isOnStock: channel.availability.isOnStock,
        channel: channel.channel,
      });
    }
  });

  return offers;
}

export function getSupplementalProductVariantOffers(
  variant: SupplementalProductVariantDataFragment,
): Sproutl.ProductOffer[] {
  const offers: Sproutl.ProductOffer[] = [];

  variant?.availability?.channels.results.forEach((channel) => {
    const price = variant?.prices?.find(
      (priceOption) => priceOption?.channel?.key === channel?.channel?.key,
    );

    if (channel.channel?.name && channel.channel.key && price) {
      offers.push({
        partner: {
          name: channel.channel.name,
          slug: channel.channel.key,
        },
        price: price.value,
        isOnStock: channel.availability.isOnStock,
        channel: channel.channel,
      });
    }
  });

  return offers;
}

export function sortOffersByBestPrice(
  a: Sproutl.ProductOffer,
  b: Sproutl.ProductOffer,
) {
  return a.price.centAmount - b.price.centAmount;
}

export function pickBestOffer(commerce: ProductAvailabilityDataFragment) {
  const offers = getOffers(commerce).sort(sortOffersByBestPrice);
  const onStockOffers = offers.filter((offer) => offer.isOnStock);

  return onStockOffers.length
    ? onStockOffers[0]
    : offers.length
    ? offers[0]
    : null;
}

/*
 * TODO: logic optimisation here; ability to specify a preferred channel (the channel of the main product),
 * to reduce the chance of a user getting a split-channel basket
 */
export function pickBestSupplementalProductVariantOffer(
  variant: SupplementalProductVariantDataFragment,
): Sproutl.ProductOffer | null {
  const offers = getSupplementalProductVariantOffers(variant).sort(
    sortOffersByBestPrice,
  );
  const onStockOffers = offers.filter((offer) => offer.isOnStock);

  return onStockOffers.length
    ? onStockOffers[0]
    : offers.length
    ? offers[0]
    : null;
}

export async function mapSuggestedSupplementalProducts(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  suggestedPots: Sproutl.ProductVariant[] = [],
): Promise<Sproutl.ProductVariant[]> {
  if (!suggestedPots) {
    return [];
  }

  const suggestedSkus = compact(map(suggestedPots, 'sku'));

  if (!suggestedSkus?.length) {
    return [];
  }

  try {
    const suggestedProducts = await apolloClient.query<
      GetSupplementalProductsQuery,
      GetSupplementalProductsQueryVariables
    >({
      query: GetSupplementalProductsDocument,
      variables: {
        skus: suggestedSkus,
      },
    });

    const suggestedVariants = chain(suggestedProducts.data.products.results)
      .flatMap('masterData.current.allVariants')
      .map((suggestedVariant) => ({
        ...suggestedVariant,
        bestOffer: pickBestSupplementalProductVariantOffer(suggestedVariant),
      }))
      .keyBy('sku')
      .value();

    return chain(suggestedPots)
      .compact()
      .map((suggestedPot) => ({
        ...suggestedPot,
        ...suggestedVariants[suggestedPot.sku],
      }))
      .filter(({ bestOffer }) => bestOffer && bestOffer.isOnStock)
      .value();
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
}

export function getStockAvailabilityFromItem(item: LineItemDataFragment) {
  const channelKey = item.distributionChannel?.key;

  const itemAvailability = item.variant?.availability?.channels.results.find(
    (prodItem) => prodItem.channel?.key === channelKey,
  );

  return (
    itemAvailability?.availability || {
      isOnStock: false,
      availableQuantity: 0,
    }
  );
}
