import React from 'react';
import Link from 'next/link';
import { relativeUrl } from 'utils/helpers';
import { ILinkProps } from './types';
import { SLink } from './styles';

const SiteLink: React.VFC<ILinkProps> = ({
  href,
  title,
  onClick,
  onMouseOver,
  onFocus,
  children,
  className,
  variant = 'primary',
  scroll,
  target,
  external,
  active,
  ariaLabel,
  underline,
}) => (
  <Link href={relativeUrl(href)} scroll={scroll} passHref>
    <SLink
      title={title}
      onClick={onClick}
      className={className}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      variant={variant}
      target={external ? '_blank' : target}
      rel={external ? 'noopener noreferrer' : undefined}
      active={active}
      aria-label={ariaLabel}
      underline={underline}
    >
      {children}
    </SLink>
  </Link>
);

export default SiteLink;
