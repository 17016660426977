import styled from 'styled-components';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface Heading3Props {
  inline?: boolean;
}

const Heading3 = styled.h3<Heading3Props>((props) => ({
  marginTop: 0,
  marginBottom: props.inline ? 0 : 20,
  fontSize: 32,
  lineHeight: '38px',
  fontWeight: 'bold',
  color: 'inherit',
  fontFamily: theme.fonts.heading,
  'p + &, h1 + &, h2 + &': {
    marginTop: '1.5em',
  },
  [desktop]: {
    fontSize: 48,
    lineHeight: '54px',
  },
}));

export default Heading3;
