import { useRouter } from 'next/router';
import { CHECKOUT_STEPS } from 'pages/checkout/hooks';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { ActiveStep } from 'utils/checkout/types';
import { IGroupedCart, MappedSearchResponse } from 'utils/commercetools/types';
import {
  trackAddDeliveryInfo,
  trackAddPaymentInfo,
  trackBeginCheckout,
  trackViewBasket,
  trackViewProduct,
  trackViewProductsList,
} from './events';
import { dataLayerPageView } from './utils';

/**
 * useGtm hook which initialises Google Tag Manager
 */
export function useGtm() {
  const router = useRouter();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
        events: {
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
          ecommerce: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', dataLayerPageView);
    return () => {
      router.events.off('routeChangeComplete', dataLayerPageView);
    };
  }, [router.events]);
}

export function useTrackViewProductsList(
  list: string,
  search: MappedSearchResponse,
) {
  useEffect(() => {
    const products = search.results;
    trackViewProductsList(list, products);
  }, [list, search]);
}

export function useTrackViewProduct(
  product: Sproutl.ProductVariant,
  bestOffer: Sproutl.ProductOffer | null,
) {
  useEffect(() => {
    trackViewProduct(product, bestOffer);
  }, [bestOffer, product]);
}

export function useTrackViewBasket(cart: IGroupedCart | null) {
  useEffect(() => {
    if (!cart?.lineItems || !cart?.totalPrice) {
      return;
    }

    trackViewBasket(cart.totalPrice, cart.lineItems);
  }, [cart?.totalPrice, cart?.lineItems]);
}

export function useTrackCheckout(
  cart: IGroupedCart | null,
  activeStep: ActiveStep,
) {
  useEffect(() => {
    if (!cart?.lineItems || !cart?.totalPrice) {
      return;
    }

    switch (activeStep) {
      case CHECKOUT_STEPS.DETAILS:
        trackBeginCheckout(cart.totalPrice, cart.lineItems);
        break;
      case CHECKOUT_STEPS.DELIVERY:
        trackAddDeliveryInfo(cart.totalPrice, cart.lineItems);
        break;
      case CHECKOUT_STEPS.PAYMENT:
        trackAddPaymentInfo(cart.totalPrice, cart.lineItems);
        break;
    }
  }, [activeStep, cart?.totalPrice, cart?.lineItems]);
}
