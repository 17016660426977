import React from 'react';

export default function GooglePay() {
  return (
    <svg
      width={60}
      height={24}
      viewBox="0 0 60 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.425 11.784v6.998h-2.197V1.498h5.826a5.217 5.217 0 012.034.367 5.26 5.26 0 011.738 1.13c.505.459.907 1.022 1.179 1.65.272.63.407 1.31.397 1.997a4.834 4.834 0 01-.39 2.009 4.787 4.787 0 01-1.186 1.66c-1.018.982-2.276 1.472-3.772 1.471h-3.629v.002zm0-8.158V9.66h3.684a2.876 2.876 0 002.173-.894 2.972 2.972 0 000-4.224 2.864 2.864 0 00-2.173-.917h-3.684v.001zM42.463 6.57c1.624 0 2.905.439 3.845 1.316.94.877 1.409 2.08 1.408 3.608v7.288h-2.101v-1.641h-.096c-.91 1.352-2.119 2.027-3.629 2.027-1.288 0-2.367-.386-3.234-1.158a3.694 3.694 0 01-.975-1.3 3.733 3.733 0 01-.327-1.596c0-1.224.458-2.197 1.373-2.92.915-.722 2.136-1.085 3.664-1.087 1.304 0 2.379.242 3.223.725v-.508a2.55 2.55 0 00-.908-1.965 3.092 3.092 0 00-2.12-.81c-1.227 0-2.198.523-2.913 1.57L37.74 8.887c1.064-1.544 2.639-2.317 4.724-2.317zm-2.841 8.592a1.788 1.788 0 00.728 1.448 2.66 2.66 0 001.707.58 3.484 3.484 0 002.47-1.038c.728-.692 1.092-1.504 1.092-2.437-.685-.551-1.64-.827-2.865-.827-.892 0-1.636.217-2.232.652-.602.442-.9.978-.9 1.622zM59.776 6.956l-7.334 17.039h-2.268l2.722-5.962-4.823-11.077h2.388l3.486 8.495h.047l3.39-8.495h2.392z"
        fill="#5F6368"
      />
      <path
        d="M19.549 10.275c0-.677-.056-1.352-.17-2.02h-9.263v3.825h5.306a4.623 4.623 0 01-.676 1.712 4.572 4.572 0 01-1.286 1.306v2.482h3.167c1.854-1.728 2.922-4.284 2.922-7.305z"
        fill="#4285F4"
      />
      <path
        d="M10.116 19.978c2.65 0 4.883-.88 6.51-2.397L13.46 15.1c-.882.604-2.017.949-3.344.949-2.562 0-4.737-1.746-5.515-4.099H1.34v2.558A9.89 9.89 0 004.96 18.5a9.745 9.745 0 005.156 1.478z"
        fill="#34A853"
      />
      <path
        d="M4.601 11.949a6.01 6.01 0 010-3.801V5.59H1.34a10.017 10.017 0 000 8.917l3.262-2.558z"
        fill="#FBBC04"
      />
      <path
        d="M10.116 4.05a5.302 5.302 0 013.768 1.489l2.804-2.834A9.383 9.383 0 0010.116.119C8.296.119 6.51.63 4.96 1.597A9.89 9.89 0 001.34 5.59L4.6 8.148c.778-2.353 2.953-4.099 5.515-4.099z"
        fill="#EA4335"
      />
    </svg>
  );
}
