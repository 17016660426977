import styled from 'styled-components';
import theme from 'utils/theme';

interface ILinkProps {
  variant?: 'primary' | 'secondary';
  active?: boolean;
  underline?: boolean;
}

export const SLink = styled.a(
  ({ variant, active, underline = false }: ILinkProps) => {
    const hoverStyles = {
      color: variant === 'primary' ? theme.colors.black : theme.colors.purple,
    };

    return {
      color:
        variant === 'primary' ? theme.colors.darkGreen : theme.colors.black,
      textDecoration: underline ? 'underline' : 'none',
      '&:hover': hoverStyles,
      '&:focus': {
        color: variant === 'primary' ? theme.colors.black : theme.colors.purple,
        outline: `3px solid`,
      },
      '&:focus:not(:focus-visible)': {
        outline: 'none',
      },
      ...(active
        ? {
            color: `${hoverStyles.color} !important`,
          }
        : {}),
    };
  },
);
