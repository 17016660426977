import React, { useState } from 'react';
import Logo from 'components/Logo';
import { IconButton } from 'components/global/Header/styles';
import { Search as SearchIcon, Close } from 'components/icons';
import { AnimatePresence } from 'framer-motion';
import {
  Input,
  SearchOverlay,
  SearchForm,
  SearchIconLabel,
  SearchLogoWrapper,
  SearchHeaderInner,
  SearchHeaderWrapper,
} from './styles';

const Search = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SearchIconLabel
        as="label"
        htmlFor="search-input"
        data-testid="search"
        onClick={() => setIsOpen(!isOpen)}
      >
        <SearchIcon />
        Search
      </SearchIconLabel>
      <AnimatePresence>
        {isOpen ? (
          <SearchOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeOut', duration: 0.2 }}
          >
            <SearchHeaderWrapper>
              <SearchHeaderInner>
                <SearchLogoWrapper>
                  <Logo />
                </SearchLogoWrapper>
                <SearchForm action="/search" method="get">
                  <SearchIcon />
                  <Input
                    name="q"
                    type="search"
                    id="search-input"
                    placeholder="Search"
                    onFocus={() => setIsOpen(true)}
                  />
                </SearchForm>
                <IconButton type="button" onClick={() => setIsOpen(false)}>
                  <Close width={20} height={20} />
                </IconButton>
              </SearchHeaderInner>
            </SearchHeaderWrapper>
          </SearchOverlay>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default Search;
