import React from 'react';

export default function CardPaymentMethods() {
  return (
    <svg
      width="155"
      height="40"
      viewBox="0 0 155 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.031 13.453h-2.62c-.791 0-1.4.244-1.766 1.036L33.65 25.88h3.533s.61-1.523.731-1.888h4.325c.122.426.427 1.827.427 1.827h3.167l-2.802-12.366zm-4.142 7.98c.304-.73 1.34-3.472 1.34-3.472 0 .06.304-.731.426-1.157L40.9 17.9s.67 2.985.792 3.594h-2.802v-.06zM33.894 21.799c0 2.558-2.315 4.264-5.91 4.264-1.522 0-2.984-.305-3.776-.67l.487-2.802.427.182c1.096.488 1.827.67 3.167.67.975 0 2.01-.365 2.01-1.218 0-.548-.426-.914-1.766-1.523-1.28-.609-2.985-1.584-2.985-3.35 0-2.437 2.376-4.082 5.726-4.082 1.28 0 2.376.244 3.046.549l-.487 2.68-.244-.244a6.548 6.548 0 0 0-2.558-.487c-1.28.06-1.889.61-1.889 1.096 0 .549.731.975 1.889 1.523 1.949.914 2.863 1.95 2.863 3.412z"
        fill="#3362AB"
      />
      <path
        d="m4.167 13.575.06-.244h5.24c.73 0 1.278.244 1.461 1.036l1.158 5.482c-1.158-2.924-3.838-5.3-7.92-6.274z"
        fill="#F9B50B"
      />
      <path
        d="m19.457 13.453-5.3 12.366h-3.594L7.517 15.463c2.193 1.402 4.02 3.595 4.69 5.117l.366 1.28 3.29-8.468h3.594v.061zM20.858 13.392h3.35L22.076 25.82h-3.35l2.132-12.427z"
        fill="#3362AB"
      />
      <path
        d="m85.217 9.8-10.76.036.327 19.698 10.759-.037-.326-19.698z"
        fill="#FF5F00"
      />
      <path
        d="M75.166 19.725a12.465 12.465 0 0 1 4.54-9.875c-2.123-1.665-4.783-2.667-7.666-2.657-6.832.024-12.266 5.652-12.15 12.584.115 6.933 5.735 12.523 12.567 12.5 2.883-.01 5.51-1.03 7.576-2.709-2.887-2.255-4.8-5.837-4.867-9.843z"
        fill="#EB001B"
      />
      <path
        d="M100.111 19.556c.115 6.933-5.318 12.56-12.15 12.584-2.883.01-5.543-.991-7.665-2.656 2.845-2.31 4.605-5.87 4.539-9.876a12.83 12.83 0 0 0-4.867-9.843 12.025 12.025 0 0 1 7.576-2.708c6.832-.024 12.453 5.601 12.567 12.5z"
        fill="#F79E1B"
      />
      <path
        d="M117.5 5.624h35c1.38 0 2.5 1.12 2.5 2.502v23.75a2.5 2.5 0 0 1-2.5 2.5h-35a2.5 2.5 0 0 1-2.5-2.5V8.126a2.501 2.501 0 0 1 2.5-2.502z"
        fill="#26A6D1"
      />
      <path
        d="m121.45 15.624-3.95 8.742h4.729l.586-1.394h1.34l.586 1.394h5.205v-1.064l.464 1.064h2.692l.464-1.087v1.087h10.825l1.317-1.358 1.232 1.358 5.56.01-3.962-4.357 3.962-4.395h-5.474l-1.281 1.333-1.194-1.333h-11.776l-1.011 2.257-1.035-2.257h-4.719v1.028l-.525-1.028h-4.035zm.915 1.242h2.305l2.62 5.927v-5.927h2.525l2.024 4.25 1.865-4.25h2.512v6.272h-1.528l-.013-4.915-2.229 4.915h-1.367l-2.241-4.915v4.915h-3.145l-.597-1.406h-3.221l-.595 1.405h-1.685l2.77-6.271zm15.25 0h6.216l1.902 2.053 1.962-2.053h1.901l-2.888 3.152 2.888 3.116h-1.987l-1.901-2.077-1.973 2.077h-6.12v-6.268zm-14.129 1.06-1.061 2.506h2.121l-1.06-2.505zm15.664.238v1.145h3.391v1.277h-3.391v1.25h3.804l1.767-1.842-1.692-1.83h-3.879z"
        fill="#fff"
      />
    </svg>
  );
}
