import styled from 'styled-components';
import { desktop } from 'utils/media';
import theme from 'utils/theme';
import Container from 'components/layout/Container';
import Link from 'components/Link';

export const MOBILE_NAV_HEIGHT = 52;
export const DESKTOP_NAV_HEIGHT = 100;

interface IActiveProps {
  isActive?: boolean;
}

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const Background = styled.nav<IActiveProps>(({ isActive = false }) => ({
  position: 'relative',
  zIndex: theme.zIndexes.header,
  backgroundColor: theme.colors.irisPurple,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: MOBILE_NAV_HEIGHT,

  [desktop]: {
    backgroundColor: isActive ? theme.colors.white : theme.colors.purple,
    height: DESKTOP_NAV_HEIGHT,
    transition: isActive
      ? `background-color ${theme.transitionSpeeds.fast}s ease-out`
      : `background-color ${theme.transitionSpeeds.fastest}s ease-out`,
  },
}));

export const StyledContainer = styled(Container)({
  display: 'flex',
  padding: '0 6px 0 25px',
  position: 'relative',

  [desktop]: {
    padding: '0 0 0 20px',
  },
});

export const LogoLink = styled.a<IActiveProps>(({ isActive = false }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.colors.white,
  fontSize: 0,
  lineHeight: 0,
  height: MOBILE_NAV_HEIGHT,

  [desktop]: {
    color: isActive ? theme.colors.irisPurple : theme.colors.white,
    height: DESKTOP_NAV_HEIGHT,
    transition: isActive
      ? `color ${theme.transitionSpeeds.fast}s ease-out`
      : `color ${theme.transitionSpeeds.normal}s ease-out`,
  },

  svg: {
    height: 20,
    width: 'auto',
    [desktop]: {
      height: 30,
    },
  },
}));

export const LogoWrapper = styled.div`
  ${desktop} {
    width: 200px;
    min-width: 200px;
  }
  flex-shrink: 0;
`;

export const HeaderNavLinks = styled.ul({
  alignItems: 'center',
  display: 'none',
  justifyContent: 'flex-start',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  width: '100%',

  a: {
    fontWeight: theme.weights.bold,
    marginRight: 30,
    color: theme.colors.black,

    '&:hover,&:focus': {
      color: theme.colors.black,
    },
  },

  [desktop]: {
    display: 'flex',
  },
});

export const HamburgerTempWrapper = styled.button({
  width: MOBILE_NAV_HEIGHT,
  height: MOBILE_NAV_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
  border: 0,

  [desktop]: {
    display: 'none',
  },
});

export const IconLink = styled(Link)({
  color: theme.colors.black,
});

export const IconButton = styled.button({
  width: MOBILE_NAV_HEIGHT,
  height: MOBILE_NAV_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
  border: 0,
  cursor: 'pointer',

  svg: {
    pointerEvents: 'none',
  },

  [desktop]: {
    width: 60,
    height: DESKTOP_NAV_HEIGHT,
    svg: {
      height: 30,
      width: 'auto',
    },
  },
});

export const IconsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',

  [desktop]: {
    right: 2.5,
  },
});
