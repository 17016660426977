import React from 'react';
import { ButtonEl } from './styles';
import { ButtonProps } from './types';

export default function Button({
  as,
  onClick,
  children,
  disabled,
  colorScheme,
  width = 'auto',
  type = 'button',
  variant = 'primary',
  name,
  id,
}: ButtonProps) {
  const defaultColorScheme = variant === 'primary' ? 'pineGreen' : 'black';

  return (
    <ButtonEl
      as={as}
      width={width}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      type={as ? null : type}
      colorScheme={colorScheme || defaultColorScheme}
      aria-disabled={disabled}
      name={name}
      id={id}
    >
      {children}
    </ButtonEl>
  );
}
