import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Close } from 'components/icons';
import {
  HeadingText,
  HeadingWrapper,
  StyledModal,
  Wrapper,
  CloseButton,
} from './styles';
import { IModalNotification } from './types';
import { Ref } from 'react';

const ModalNotificationInner = (
  {
    heading,
    isOpen,
    children,
    isBespokeModal,
    disableClose = false,
  }: IModalNotification,
  ref: Ref<any>,
) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const toggleModal = () => {
    setOpacity(0);
    setIsModalOpen(!isModalOpen);
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  const afterOpen = () => {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  };

  const beforeClose = () =>
    new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });

  return (
    <StyledModal
      isOpen={isModalOpen}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onBackgroundClick={disableClose ? () => {} : toggleModal}
      onEscapeKeydown={disableClose ? () => {} : toggleModal}
      opacity={opacity}
      backgroundProps={{ opacity }}
    >
      {isBespokeModal ? (
        <div>{children}</div>
      ) : (
        <Wrapper>
          <>
            <HeadingWrapper>
              <HeadingText>{heading}</HeadingText>
              {disableClose ? null : (
                <CloseButton
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                >
                  <Close height={16} width={16} />
                </CloseButton>
              )}
            </HeadingWrapper>
          </>
          <div>{children}</div>
        </Wrapper>
      )}
    </StyledModal>
  );
};

const ModalNotification = forwardRef(ModalNotificationInner);

export default ModalNotification;
