import React from 'react';

export default function Basket() {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.99 19H0V4.187h1.069c.068 0 .137 0 .206.009a7.742 7.742 0 011.93.333 8.067 8.067 0 013.315 1.94A7.868 7.868 0 018 8.45a7.334 7.334 0 011.48-1.98 8.089 8.089 0 013.314-1.93 8.72 8.72 0 011.931-.333c.07 0 .138-.01.206-.01H16V19h-.01z"
        fill="currentColor"
      />
      <path d="M11 4a3 3 0 00-6 0" stroke="currentColor" strokeWidth="1.8" />
    </svg>
  );
}
