import { Close, Help, LeftArrow, RightArrow } from 'components/icons';
import { Heading4 } from 'components/text';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import Image from 'components/Image';
import theme from 'utils/theme';
import { setMobileDialogOpen } from 'utils/ui';
import { NavLink, NavScreen } from '../types';
import {
  MobileContent,
  MobileInternalButton,
  MobileInternalNavigation,
  MobileNavScreen,
  MobileNavScreenRows,
  NavRowButton,
  NavRowLink,
  ScreenHero,
  ScreenHeroHeading,
  Wrapper,
  ScreenFooterLinks,
  ScreenView,
  ScreenHeroUnderlay,
  MobileInternalButtonAnimation,
} from './styles';
import {
  MegaNavMobileProps,
  MobileNavRowProps,
  MobileNavScreenContentProps,
} from './types';

function MobileNavRow({ item, onNavigateToScreen }: MobileNavRowProps) {
  const isButton =
    ('subItems' in item && item.subItems?.length) || 'links' in item;

  const children = (
    <>
      <span>{item?.title}</span>
      {isButton ? <RightArrow /> : null}
    </>
  );

  return isButton ? (
    <NavRowButton
      onClick={() => {
        onNavigateToScreen(item as NavScreen);
      }}
    >
      {children}
    </NavRowButton>
  ) : (
    <NavRowLink variant="secondary" href={(item as NavLink).path || '#'}>
      {children}
    </NavRowLink>
  );
}

function MobileNavScreenContent({
  item,
  onNavigate,
}: MobileNavScreenContentProps) {
  let rows;
  if (Array.isArray(item)) {
    rows = item;
  } else {
    rows =
      'links' in item ? item.links : 'subItems' in item ? item.subItems : null;
  }

  return (
    <ScreenView>
      {'title' in item ? (
        <ScreenHero aspectRatio={0.3866666}>
          {item.heroImage ? (
            <>
              <Image
                src={item.heroImage}
                aspectRatio={0.3866666}
                alt=""
                sizes="100vw"
              />
              <ScreenHeroUnderlay />
            </>
          ) : null}
          <ScreenHeroHeading hasImage={!!item.heroImage}>
            <Heading4 inline>{item.title}</Heading4>
          </ScreenHeroHeading>
        </ScreenHero>
      ) : null}
      <MobileNavScreenRows>
        {(item as NavScreen)?.path ? (
          <NavRowLink
            variant="secondary"
            href={(item as NavScreen).path || '#'}
          >
            View All
          </NavRowLink>
        ) : null}
        {rows?.map((subItem) => (
          <MobileNavRow
            item={subItem}
            key={subItem._key}
            onNavigateToScreen={onNavigate}
          />
        ))}
      </MobileNavScreenRows>
      <ScreenFooterLinks>
        <NavRowLink variant="secondary" href="/about/contact-us">
          <span>
            <Help />
            Need some help?
          </span>
        </NavRowLink>
      </ScreenFooterLinks>
    </ScreenView>
  );
}

export default function MegaNavMobile({ nav, onClose }: MegaNavMobileProps) {
  const [currentScreens, setCurrentScreens] = useState<NavScreen[]>([]);

  useEffect(() => {
    setMobileDialogOpen(true);

    return () => {
      setMobileDialogOpen(false);
    };
  }, []);

  const screensPush = (screen: NavScreen) => {
    const screenToPush =
      'subItems' in screen && screen.subItems && screen.subItems.length === 1
        ? screen.subItems[0]
        : screen;

    setCurrentScreens([...currentScreens, screenToPush]);
  };

  const screensBack = () => {
    const screenWrapperEl = document.getElementById('mobileNavScreens');
    if (screenWrapperEl) {
      const screenNodes = screenWrapperEl.childNodes;
      const previousScreenEl = screenNodes.item(screenNodes.length - 2);
      if (previousScreenEl) {
        (previousScreenEl as HTMLDivElement)?.focus();
      }
    }

    const newScreens = [...currentScreens];
    newScreens.pop();
    setCurrentScreens(newScreens);
  };

  return (
    <Wrapper
      initial={{ translateX: '100%' }}
      animate={{ translateX: '0%' }}
      exit={{ translateX: '100%' }}
      transition={{ ease: 'easeOut', duration: theme.transitionSpeeds.fast }}
    >
      <MobileInternalNavigation>
        <div>
          <AnimatePresence>
            {currentScreens.length ? (
              <MobileInternalButtonAnimation
                initial={{
                  opacity: 0,
                  translateX: '10px',
                }}
                animate={{
                  opacity: 1,
                  translateX: '0px',
                  transition: {
                    ease: 'easeOut',
                    delay: 0.25,
                    duration: theme.transitionSpeeds.fast,
                  },
                }}
                exit={{
                  opacity: 0,
                  translateX: '10px',
                }}
                transition={{
                  ease: 'easeOut',
                  duration: theme.transitionSpeeds.fast,
                }}
              >
                <MobileInternalButton type="button" onClick={screensBack}>
                  <LeftArrow />
                </MobileInternalButton>
              </MobileInternalButtonAnimation>
            ) : null}
          </AnimatePresence>
        </div>
        <MobileInternalButton type="button" onClick={onClose}>
          <Close />
        </MobileInternalButton>
      </MobileInternalNavigation>
      <MobileContent id="mobileNavScreens">
        <AnimatePresence>
          <MobileNavScreen tabIndex={0} key="initialScreen">
            <MobileNavScreenContent item={nav} onNavigate={screensPush} />
          </MobileNavScreen>
          {currentScreens.map((screen) => (
            <MobileNavScreen
              tabIndex={0}
              key={`subScreen_${screen._key}`}
              initial={{ translateX: 'calc(100% + 40px)' }}
              animate={{ translateX: '0%' }}
              exit={{ translateX: 'calc(100% + 40px)' }}
              transition={{
                ease: 'easeOut',
                duration: theme.transitionSpeeds.normal,
              }}
            >
              <MobileNavScreenContent item={screen} onNavigate={screensPush} />
            </MobileNavScreen>
          ))}
        </AnimatePresence>
      </MobileContent>
    </Wrapper>
  );
}
