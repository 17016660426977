import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGroupedActiveCart } from 'utils/commercetools/cart';
import * as Sentry from '@sentry/nextjs';
import { initializeApollo } from 'utils/apollo-client';
import {
  AddressDataFragment,
  Maybe,
  MyPaymentVersionFragmentDoc,
  PaymentVersionFragmentDoc,
} from 'generated/api/graphql';
import {
  ActiveStep,
  AddressFields,
  UseTotalsResult,
  MaybeCartOrOrder,
  UsePayNowProps,
  UsePayNowResult,
  PayNowError,
} from 'utils/checkout/types';
import { scrollToTop } from 'utils/helpers';
import { getAddressFromObject } from 'utils/address';
import { checkoutConfirmOrder } from 'utils/checkout/confirm-order';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

const CHECKOUT_STEPS_ARRAY: ActiveStep[] = [
  'basket',
  'details',
  'delivery',
  'payment',
];

export const CHECKOUT_STEPS: Record<Uppercase<ActiveStep>, ActiveStep> = {
  BASKET: 'basket',
  DETAILS: 'details',
  DELIVERY: 'delivery',
  PAYMENT: 'payment',
};

const CHECKOUT_STEPS_INDEX: Record<Uppercase<ActiveStep>, number> = {
  BASKET: 0,
  DETAILS: 1,
  DELIVERY: 2,
  PAYMENT: 3,
};

function getInitialActiveStep(hasShippingAddress: boolean): ActiveStep {
  if (hasShippingAddress) {
    return CHECKOUT_STEPS.DELIVERY;
  }
  return CHECKOUT_STEPS.DETAILS;
}

/**
 * Hook for setting up the checkout steps
 * @returns
 */
export function useCheckoutSteps() {
  const { data: groupedActiveCart } = useGroupedActiveCart();

  const hasShippingAddress = !!groupedActiveCart?.shippingAddress?.streetName;

  const [activeStep, setActiveStep] = useState<ActiveStep>(
    getInitialActiveStep(hasShippingAddress),
  );

  const handleNextStep = useCallback(() => {
    setActiveStep((currentActiveStep) => {
      if (currentActiveStep === CHECKOUT_STEPS.DETAILS) {
        return CHECKOUT_STEPS.DELIVERY;
      }
      if (currentActiveStep === CHECKOUT_STEPS.DELIVERY) {
        return CHECKOUT_STEPS.PAYMENT;
      }
      return currentActiveStep;
    });
    scrollToTop();
  }, []);

  const handleBackToDetailsStep = useCallback(() => {
    setActiveStep(CHECKOUT_STEPS.DETAILS);
    scrollToTop();
  }, []);

  const handleBackToDeliveryStep = useCallback(() => {
    setActiveStep(CHECKOUT_STEPS.DELIVERY);
    scrollToTop();
  }, []);

  const isActiveStepAfterDetails = useCallback(
    () =>
      CHECKOUT_STEPS_ARRAY.indexOf(activeStep) > CHECKOUT_STEPS_INDEX.DETAILS,
    [activeStep],
  );

  const isActiveStepAfterDelivery = useCallback(
    () =>
      CHECKOUT_STEPS_ARRAY.indexOf(activeStep) > CHECKOUT_STEPS_INDEX.DELIVERY,
    [activeStep],
  );

  return {
    activeStep,
    handleNextStep,
    handleBackToDetailsStep,
    handleBackToDeliveryStep,
    isActiveStepAfterDetails,
    isActiveStepAfterDelivery,
  };
}

/**
 * Hook to update the Payment Intent and CT Payment with the latest totalPrice
 * @param {number} totalPrice
 * @returns
 */
export function useUpdatePaymentIntent(totalPrice: number) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (totalPrice) {
      setLoading(true);
      setError(null);

      axios({
        method: 'post',
        url: `/api/checkout/update-payment-intent`,
      })
        .then(
          (
            result: AxiosResponse<{
              success: boolean;
              payment: { id: string; version: number };
            }>,
          ) => {
            setLoading(false);

            const apolloClient = initializeApollo();

            // MyPayment is used by client-side requests
            apolloClient.writeFragment({
              id: `MyPayment:${result.data.payment.id}`,
              fragment: MyPaymentVersionFragmentDoc,
              data: {
                id: result.data.payment.id,
                version: result.data.payment.version,
              },
            });

            // Payment is used by server-side requests
            apolloClient.writeFragment({
              id: `Payment:${result.data.payment.id}`,
              fragment: PaymentVersionFragmentDoc,
              data: {
                id: result.data.payment.id,
                version: result.data.payment.version,
              },
            });
          },
        )
        .catch((e) => {
          setError(
            "Sorry, there was an error updating your cart's payment. Please refresh to try again.",
          );
          Sentry.captureException(
            new Error('Payment intent update failed in checkout'),
            {
              contexts: {
                originalException: e,
                axiosResponse: e.response,
                axiosRequest: e.request,
              },
            },
          );
        });
    }
  }, [totalPrice, setLoading]);

  return { loading, error };
}

/**
 * Hook to return a concatendated string of an address object
 * @param {Maybe<AddressFields | AddressDataFragment>} address
 * @param {boolean} excludeName
 * @returns {string | null} addressPreview
 */
export function useAddressPreview(
  address: Maybe<AddressFields | AddressDataFragment>,
  excludeName: boolean = false,
): string | null {
  return useMemo(() => {
    if (!address) {
      return null;
    }

    const addressArray = [
      `${address?.firstName || ''} ${address?.lastName || ''}`,
      address?.streetName,
      address?.additionalStreetInfo,
      address?.city,
      address?.postalCode,
    ];

    if (excludeName === true) {
      addressArray.shift();
    }

    const addressPreview = addressArray
      .map((val) => (val && typeof val === 'string' ? val.trim() : ''))
      .filter((val) => val)
      .join(', ');

    return addressPreview;
  }, [address, excludeName]);
}

/**
 * Hook to get the computed cart or order totals
 * @param cartOrOrder
 * @returns
 */
export function useTotals(cartOrOrder: MaybeCartOrOrder): UseTotalsResult {
  return useMemo(() => {
    const totals = {
      subtotal: 0,
      qty: 0,
      discount: 0,
    };

    if (!cartOrOrder || !cartOrOrder?.lineItems) {
      return totals;
    }

    cartOrOrder.lineItems.forEach((lineItem) => {
      // Discounts
      lineItem.discountedPricePerQuantity.forEach(
        (discountedPricePerQuantity) => {
          discountedPricePerQuantity.discountedPrice.includedDiscounts.forEach(
            (includedDiscount) => {
              totals.discount -=
                includedDiscount.discountedAmount.centAmount *
                discountedPricePerQuantity.quantity;
            },
          );
        },
      );

      // Subtotal
      totals.subtotal += lineItem.price.value.centAmount * lineItem.quantity;

      // Qty
      totals.qty += lineItem.quantity;
    });

    return totals;
  }, [cartOrOrder]);
}

/**
 * Hook to trigger the confirm order API and confirm the stripe payment
 * @param {UsePayNowProps} props
 * @returns {UsePayNowResult}
 */
export function usePayNow({
  order,
  setOrder,
  setIsLoading,
  setPayNowError,
  setPaymentIntent,
  setPayNowErrorTime,
  paymentIntentClientSecret,
}: UsePayNowProps): UsePayNowResult {
  const { data: groupedCart } = useGroupedActiveCart();

  const shippingAddress = groupedCart?.shippingAddress;

  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = useCallback(
    async (values) => {
      const setError = (error: PayNowError) => {
        setPayNowError(error);
        setPayNowErrorTime(new Date());
      };

      // Stripe.js has not loaded yet
      if (!stripe || !elements || !shippingAddress) return;
      const cardNumberElement = elements.getElement(CardNumberElement);
      // CardNumber element is not available
      if (!cardNumberElement) return;

      setIsLoading(true);
      setError(null);

      // Get billing address either from Shipping Address or form values
      let newBillingAddress;
      if (values.billingSameAsShipping === true) {
        newBillingAddress = getAddressFromObject(shippingAddress);
      } else {
        // The billing address form doesn't have email or phone number fields
        newBillingAddress = {
          ...getAddressFromObject(values),
          phone: shippingAddress?.phone,
          email: shippingAddress?.email,
        };
      }

      try {
        // Don't recreate an order if it's already been created
        // @todo add in scenario to update the order billing address after it's created
        if (!order) {
          // Call confirm order API
          const newOrder = await checkoutConfirmOrder({
            billingAddress: newBillingAddress,
          });

          setOrder(newOrder);
        }

        // Pay with stripe
        // Create a Stripe PaymentMethod from the card details, this will later
        // be used to confirm the payment intent
        const stripePayload = await stripe.confirmCardPayment(
          paymentIntentClientSecret,
          {
            payment_method: {
              card: cardNumberElement,
              billing_details: {
                address: {
                  city: newBillingAddress.city,
                  line1: newBillingAddress.streetName,
                  line2: newBillingAddress.additionalStreetInfo,
                  postal_code: newBillingAddress.postalCode,
                  country: newBillingAddress.country,
                },
                name: values.ccname,
                email: shippingAddress.email || undefined,
                phone: shippingAddress.phone || undefined,
              },
            },
          },
        );

        if (stripePayload.error) {
          setError(`Payment failed, ${stripePayload.error.message}`);
          setIsLoading(false);
        } else {
          setPaymentIntent(stripePayload.paymentIntent);
          setIsLoading(false);
        }
      } catch (e) {
        setError(`${e}`);
        setIsLoading(false);

        Sentry.captureException(e, {
          contexts: {
            request: e.request,
            response: e.response,
          },
        });
      }
    },
    [
      order,
      stripe,
      elements,
      setOrder,
      setIsLoading,
      setPayNowError,
      shippingAddress,
      setPaymentIntent,
      setPayNowErrorTime,
      paymentIntentClientSecret,
    ],
  );

  return {
    onPaymentFormSubmit: onSubmit,
  };
}
