import React from 'react';

export default function Search() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.053 17.599a8.051 8.051 0 008.054-8.05A8.051 8.051 0 009.053 1.5 8.051 8.051 0 001 9.55a8.051 8.051 0 008.053 8.049zM16.527 17.03L21 21.5"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
