import SdkAuth, { TokenProvider } from '@commercetools/sdk-auth';
import jsCookie from 'js-cookie';
import SsCookie from 'cookies';
import { makeVar } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { RequestContext } from '../types';

const CT_TOKEN_COOKIE_NAME = 'sprtl.ct.token';

/**
 * Checks if a commercetools token cookie exists
 * @returns {boolean}
 */
export function hasAnonymousToken(): boolean {
  return !!(
    typeof window !== 'undefined' && jsCookie.get(CT_TOKEN_COOKIE_NAME)
  );
}

export const hasAnonymousTokenCached = makeVar(hasAnonymousToken());

/**
 * Creates a new commercetools auth client and token provider then gets an
 * updated anonymous token
 * @param {object | undefined} existingToken
 * @returns {object} Token object
 */
async function fetchToken(existingToken: Record<string, unknown> | undefined) {
  const authClient = new SdkAuth({
    host: 'https://auth.commercetools.com',
    projectKey: process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY,
    disableRefreshToken: false,
    credentials: {
      clientId: process.env.NEXT_PUBLIC_COMMERCETOOLS_CLIENT_ID,
      clientSecret: process.env.NEXT_PUBLIC_COMMERCETOOLS_CLIENT_SECRET,
    },
    scopes: [
      `view_products:${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
      `manage_my_orders:${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
      `manage_my_payments:${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
    ],
  });

  const tokenProvider = new TokenProvider(
    {
      sdkAuth: authClient,
      fetchTokenInfo: (sdkAuth: any) => sdkAuth.anonymousFlow(),
      // onTokenInfoChanged: () => console.log('Token changed!'),
    },
    existingToken,
  );

  const token = await tokenProvider.getTokenInfo();

  return token;
}

/**
 * Get the commercetools token object from the browser cookie
 * @returns {object} Token object
 */
export async function getAnonymousToken() {
  const tokenCookieValue = jsCookie.get(CT_TOKEN_COOKIE_NAME);

  let existingToken;
  try {
    existingToken = tokenCookieValue ? JSON.parse(tokenCookieValue) : null;
  } catch (e) {
    Sentry.captureException(e);
    console.error('getAnonymousToken: failed to parse cookie JSON');
  }

  const token = await fetchToken(existingToken);

  jsCookie.set(CT_TOKEN_COOKIE_NAME, JSON.stringify(token));

  hasAnonymousTokenCached(true);

  return token;
}

/**
 * Get the commercetools access token from the browser cookie
 * This is intended to be run client-side
 * @returns {string | undefined} Token
 */
export async function getAnonymousAccessToken() {
  const token = await getAnonymousToken();

  return token.access_token;
}

/**
 * Get the commercetools token object from the cookie inside the context object
 * This is intended to be used inside getServerSideProps or in an API endpoint
 * @param {RequestContext} ctx Object containing the request and response objects
 * @returns {object} Token object
 */
export async function getAnonymousTokenFromContext({
  req,
  res,
}: RequestContext) {
  const ssCookie = new SsCookie(req, res);

  const tokenCookieValue = ssCookie.get(CT_TOKEN_COOKIE_NAME);

  let existingToken;
  try {
    existingToken = tokenCookieValue
      ? JSON.parse(decodeURIComponent(tokenCookieValue))
      : null;
  } catch (e) {
    Sentry.captureException(e);
    console.error('getAnonymousTokenFromContext: failed to parse cookie JSON');
  }

  const token = await fetchToken(existingToken);

  return token;
}

/**
 * Get the commercetools access token from the cookie inside the context object
 * This is intended to be used inside getServerSideProps or in an API endpoint
 * @param {RequestContext} ctx Object containing the request and response objects
 * @returns {string | undefined} Token
 */
export async function getAnonymousAccessTokenFromContext(ctx: RequestContext) {
  const token = await getAnonymousTokenFromContext(ctx);

  return token.access_token;
}
