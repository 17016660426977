import React from 'react';

export default function Info() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="7.5" fill="#FFDC40" />
      <path
        d="M7.338 10.756c-.812 0-1.302.364-1.302.966 0 .588.462.938 1.232.938s1.274-.364 1.274-.924c0-.602-.462-.98-1.204-.98zM5 6.654c.378-.546.476-.672.658-.84.42-.406 1.12-.672 1.778-.672.756 0 1.288.336 1.288.784 0 .266-.14.476-.56.868l-.84.77c-.868.798-.91.882-.91 1.792 0 .294 0 .476.028.896h1.722v-.42c0-.588.112-.854.448-1.134L9.858 7.69c.672-.546 1.022-1.232 1.022-2.044C10.88 4.078 9.634 3 7.842 3 6.652 3 5.784 3.406 5 4.344v2.31z"
        fill="#000"
      />
    </svg>
  );
}
