import { LineItemDataFragment } from 'generated/api/graphql';
import TagManager from 'react-gtm-module';
import { convertCentAmountToDollars } from 'utils/helpers';
import { DataLayerPushProps } from './types';

/**
 * Clear the ecommerce object as recommended by google
 */
export function clearEcommerce() {
  TagManager.dataLayer({
    dataLayer: { ecommerce: null },
  });
}

/**
 * Helper to push events to the dataLayer after clearing the ecommerce object
 * @param event
 */
export function dataLayerPush(event: DataLayerPushProps) {
  // console.log(event);

  // Don't clear the ecom object on pageview events so the data is sent to GA
  if (!event.hasOwnProperty('page')) {
    clearEcommerce();
  }

  TagManager.dataLayer({
    dataLayer: event,
  });
}

export function dataLayerPageView(url: string) {
  dataLayerPush({
    event: 'pageview',
    page: url,
  });
}

/**
 * Maps a CT search product to the Universal Analytics format
 * @param product
 * @param index
 * @returns
 */
export function mapProductForUA(list: string) {
  return ({ name, sku, price }: Sproutl.ProductListing, index: number) => ({
    name,
    id: sku,
    price: price && convertCentAmountToDollars(price.centAmount),
    list,
    position: index + 1,
    //     name: 'Triblend Android T-Shirt', // Name or ID is required.
    //     id: '12345',
    //     price: '15.25',
    //     brand: 'Google',
    //     category: 'Apparel',
    //     variant: 'Gray',
    //     list: 'Search Results',
    //     position: 1,
  });
}

/**
 * Maps a CT search product to the GA4 format
 * @param product
 * @param index
 * @returns
 */
export function mapProductForGA4(
  { name, sku, price }: Sproutl.ProductListing,
  index: number,
) {
  return {
    item_name: name,
    item_id: sku,
    price: price && convertCentAmountToDollars(price.centAmount),
    index: index + 1,
    // item_name: 'Triblend Android T-Shirt', // Name or ID is required.
    // item_id: '12345',
    // price: 15.25,
    // item_brand: 'Google',
    // item_category: 'Apparel',
    // item_category2: 'Mens',
    // item_category3: 'Shirts',
    // item_category4: 'Tshirts',
    // item_variant: 'Gray',
    // item_list_name: 'Search Results',
    // item_list_id: 'SR123',
    // index: 1,
    // quantity: 1,
  };
}

/**
 * Maps cart/order line items to the Universal Analytics format
 * @param lineItem
 * @returns mappedLineItem
 */
export function mapLineItemForUA({
  name,
  variant,
  price,
  quantity,
}: LineItemDataFragment) {
  return {
    name,
    id: variant?.sku,
    price: price && convertCentAmountToDollars(price.value.centAmount),
    quantity,
  };
}

/**
 * Maps cart/order line items to the GA4 format
 * @param lineItem
 * @returns mappedLineItem
 */
export function mapLineItemForGA4({
  name,
  variant,
  price,
  quantity,
}: LineItemDataFragment) {
  return {
    item_id: variant?.sku,
    item_name: name,
    // coupon: 'SUMMER_FUN',
    // discount: 2.22, // discountedPricePerQuantity
    price: price && convertCentAmountToDollars(price.value.centAmount),
    quantity,
    // item_category: 'Apparel',
    // item_category2: 'Adult',
    // item_category3: 'Shirts',
    // item_category4: 'Crew',
    // item_category5: 'Short sleeve',
  };
}
