import styled from 'styled-components';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface Heading4Props {
  inline?: boolean;
}

const Heading4 = styled.h4<Heading4Props>((props) => ({
  marginTop: 0,
  marginBottom: props.inline ? 0 : 20,
  fontSize: 24,
  lineHeight: '30px',
  fontWeight: 'bold',
  color: 'inherit',
  fontFamily: theme.fonts.heading,
  ...(props.inline
    ? {}
    : {
        'p + &, h1 + &, h2 + &': {
          marginTop: '1.5em',
        },
      }),
  [desktop]: {
    fontSize: 32,
    lineHeight: '38px',
  },
}));

export default Heading4;
