import React from 'react';

export default function PaymentMethods() {
  return (
    <svg
      width={237}
      height={31}
      viewBox="0 0 237 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.843 10.526h-1.938c-.586 0-1.037.176-1.307.746L24.9 19.474h2.615s.45-1.097.54-1.36h3.201c.09.307.316 1.316.316 1.316h2.344l-2.074-8.904zm-3.065 5.746c.226-.526.992-2.5.992-2.5 0 .044.225-.526.315-.833l.18.79s.497 2.148.587 2.587h-2.074v-.044zM25.082 16.535c0 1.842-1.713 3.07-4.373 3.07-1.127 0-2.208-.219-2.794-.482l.36-2.018.316.132c.811.35 1.352.482 2.344.482.721 0 1.487-.263 1.487-.877 0-.395-.315-.658-1.307-1.096-.947-.439-2.209-1.14-2.209-2.413 0-1.754 1.758-2.938 4.238-2.938.946 0 1.758.175 2.254.395l-.361 1.93-.18-.176a4.962 4.962 0 00-1.894-.351c-.946.044-1.397.439-1.397.79 0 .394.54.701 1.397 1.096 1.443.658 2.119 1.403 2.119 2.456z"
        fill="#3362AB"
      />
      <path
        d="M3.083 10.614l.046-.175h3.876c.541 0 .947.175 1.082.745l.857 3.948c-.857-2.106-2.84-3.816-5.86-4.518z"
        fill="#F9B50B"
      />
      <path
        d="M14.398 10.527l-3.922 8.903h-2.66l-2.253-7.456c1.622 1.009 2.975 2.588 3.47 3.684l.271.921 2.434-6.096h2.66v.043zM15.435 10.483h2.48l-1.578 8.947h-2.48l1.578-8.947z"
        fill="#3362AB"
      />
      <path
        d="M68.756 7.895l-7.747.027.235 14.183 7.747-.027-.235-14.183z"
        fill="#FF5F00"
      />
      <path
        d="M61.52 15.042a8.975 8.975 0 013.267-7.11 8.868 8.868 0 00-5.519-1.913c-4.919.017-8.83 4.069-8.748 9.06.083 4.992 4.13 9.017 9.048 9a8.658 8.658 0 005.455-1.95 9.2 9.2 0 01-3.504-7.087z"
        fill="#EB001B"
      />
      <path
        d="M79.48 14.92c.083 4.992-3.83 9.044-8.748 9.06a8.868 8.868 0 01-5.52-1.912c2.05-1.662 3.317-4.225 3.27-7.11a9.237 9.237 0 00-3.505-7.087 8.658 8.658 0 015.455-1.95c4.919-.017 8.966 4.033 9.048 9z"
        fill="#F79E1B"
      />
      <path
        d="M214.385 17.484v5.04h-1.522V10.078h4.036c.482-.01.961.079 1.409.264.449.185.858.461 1.204.813.35.33.629.736.817 1.189.188.453.282.943.275 1.437.01.497-.082.99-.271 1.447a3.433 3.433 0 01-.821 1.195c-.705.707-1.576 1.06-2.613 1.06h-2.514zm0-5.873v4.344h2.552c.28.009.558-.044.817-.155.26-.11.494-.277.689-.489.193-.197.347-.434.452-.695a2.215 2.215 0 000-1.65 2.135 2.135 0 00-.452-.696 1.995 1.995 0 00-.686-.5 1.899 1.899 0 00-.82-.16h-2.552zM224.111 13.731c1.125 0 2.012.316 2.663.947.651.632.976 1.498.976 2.598v5.247h-1.456v-1.181h-.066c-.63.973-1.468 1.46-2.514 1.46-.893 0-1.64-.279-2.241-.835a2.647 2.647 0 01-.675-.935 2.776 2.776 0 01-.227-1.15c0-.88.317-1.582.951-2.102.634-.52 1.48-.78 2.539-.782.903 0 1.647.174 2.232.521v-.365a1.904 1.904 0 00-.162-.782 1.816 1.816 0 00-.466-.633 2.098 2.098 0 00-1.469-.583c-.85 0-1.523.377-2.018 1.13l-1.34-.887c.737-1.112 1.828-1.668 3.273-1.668zm-1.969 6.186c-.001.203.044.404.132.586.088.18.216.338.373.457.336.278.754.425 1.182.417a2.37 2.37 0 001.712-.747c.504-.498.756-1.083.756-1.755-.475-.397-1.136-.595-1.985-.595-.618 0-1.133.156-1.546.47-.417.317-.624.703-.624 1.167zM236.104 14.009l-5.081 12.268h-1.571l1.886-4.293-3.341-7.975h1.654l2.414 6.117h.034l2.348-6.117h1.657z"
        fill="#5F6368"
      />
      <path
        d="M208.235 16.398a8.844 8.844 0 00-.117-1.454h-6.417v2.754h3.675c-.075.44-.234.859-.468 1.232a3.22 3.22 0 01-.891.94v1.788h2.194c1.284-1.245 2.024-3.085 2.024-5.26z"
        fill="#4285F4"
      />
      <path
        d="M201.7 23.384c1.837 0 3.383-.633 4.511-1.725l-2.194-1.788c-.611.435-1.397.684-2.317.684-1.775 0-3.281-1.258-3.82-2.951h-2.26v1.841a7.033 7.033 0 002.509 2.875 6.56 6.56 0 003.571 1.064z"
        fill="#34A853"
      />
      <path
        d="M197.88 17.603a4.501 4.501 0 010-2.736v-1.842h-2.259a7.439 7.439 0 00-.725 3.21c0 1.115.248 2.214.725 3.21l2.259-1.842z"
        fill="#FBBC04"
      />
      <path
        d="M201.7 11.916a3.601 3.601 0 012.611 1.072l1.942-2.04c-1.232-1.216-2.863-1.883-4.553-1.862a6.56 6.56 0 00-3.571 1.064 7.034 7.034 0 00-2.509 2.875l2.26 1.842c.539-1.694 2.045-2.951 3.82-2.951z"
        fill="#EA4335"
      />
      <path
        d="M173.701 26.032c1.982 0 2.915-.782 3.73-3.151L181 12.533h-2.067l-2.393 8.006h-.042l-2.394-7.995h-2.125l3.444 9.849-.185.599c-.13.857-.875 1.47-1.713 1.406-.16 0-.471-.017-.597-.034v1.626c.257.036.515.053.773.053v-.011zm-6.887-5.182c-1.084 0-1.772-.543-1.772-1.363 0-.85.664-1.345 1.932-1.424l2.259-.147v.764c-.05 1.26-1.079 2.24-2.297 2.189a2.67 2.67 0 01-.122-.01v-.01zm-.545 1.605c1.23.035 2.38-.628 2.998-1.727h.042v1.626h1.881v-6.736c0-1.954-1.511-3.212-3.838-3.212-2.159 0-3.755 1.276-3.814 3.03h1.832c.219-.892 1.039-1.48 1.923-1.381 1.243 0 1.94.599 1.94 1.701v.747l-2.535.156c-2.36.147-3.637 1.146-3.637 2.882-.002 1.761 1.317 2.924 3.207 2.924l.001-.01zM155.366 10.59h2.343c1.764 0 2.772.972 2.772 2.682s-1.008 2.691-2.78 2.691h-2.335V10.59zm-2.032-1.771V22.35h2.032v-4.613h2.814c2.293.127 4.25-1.69 4.373-4.06.007-.134.008-.268.003-.402.108-2.343-1.641-4.333-3.908-4.446a4.218 4.218 0 00-.4.001l-4.914-.013zm-6.746 2.525c-1.168-.07-2.168.686-2.722.686-.554 0-1.411-.65-2.335-.633-1.224.033-2.339.733-2.931 1.841-1.26 2.24-.328 5.556.89 7.379.596.903 1.311 1.893 2.251 1.857.89-.034 1.243-.599 2.318-.599 1.076 0 1.395.6 2.335.581.974-.017 1.588-.903 2.186-1.807a8.05 8.05 0 00.974-2.078c-1.148-.519-1.892-1.687-1.898-2.982a3.366 3.366 0 011.537-2.795 3.303 3.303 0 00-2.604-1.458l-.001.008zm-.73-1.195a2.608 2.608 0 01-2.116 1.033c-.053-.83.22-1.646.756-2.266a3.124 3.124 0 012.098-1.12 3.365 3.365 0 01-.739 2.35"
        fill="#000"
      />
      <path
        d="M93.938 4.359h27.125c1.069 0 1.937.868 1.937 1.938v18.407c0 1.07-.868 1.937-1.937 1.937H93.937A1.937 1.937 0 0192 24.704V6.297c0-1.07.868-1.938 1.938-1.938z"
        fill="#26A6D1"
      />
      <path
        d="M96.999 12.109l-3.061 6.774h3.664l.455-1.08h1.038l.454 1.08h4.034v-.824l.36.824h2.086l.36-.841v.841h8.389l1.02-1.052.956 1.052 4.309.01-3.071-3.378 3.071-3.406h-4.243l-.993 1.033-.925-1.033h-9.126l-.784 1.748-.802-1.748h-3.657v.796l-.407-.796h-3.127zm.709.962h1.786l2.031 4.594V13.07h1.957l1.568 3.294 1.446-3.294h1.947v4.861h-1.185l-.01-3.81-1.727 3.81h-1.06l-1.737-3.81v3.81h-2.437l-.462-1.09h-2.497l-.461 1.09h-1.306l2.147-4.861zm11.819 0h4.817l1.474 1.591 1.521-1.591h1.473l-2.239 2.443 2.239 2.415h-1.54l-1.474-1.61-1.528 1.61h-4.743v-4.858zm-10.95.822l-.823 1.942h1.644l-.821-1.942zm12.139.184v.888h2.629v.989h-2.629v.969h2.948l1.37-1.427-1.312-1.42h-3.006v.001z"
        fill="#fff"
      />
    </svg>
  );
}
