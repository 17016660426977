import React from 'react';

interface ChevronProps {
  width?: number;
  height?: number;
}

export default function Chevron({ width = 24, height = 24 }: ChevronProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 8.991c-.759 0-1.984.328-3.036 1.347-1.025.993-1.959 2.714-2.065 5.662L12 15.96l-.9.04c-.105-2.948-1.04-4.67-2.064-5.662C7.984 9.318 6.759 8.99 6 8.99V7c1.146 0 2.807.469 4.222 1.84.693.671 1.31 1.543 1.778 2.648.468-1.105 1.085-1.977 1.778-2.648C15.194 7.469 16.854 7 18 7v1.991z"
        fill="currentColor"
      />
    </svg>
  );
}
