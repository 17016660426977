import axios from 'axios';
import Button from 'components/Button';
import Input from 'components/forms/Input';
import { Twitter, Instagram } from 'components/icons';
import SiteLink from 'components/Link';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import {
  InputRow,
  DesktopButton,
  LowerRow,
  MobileButton,
  SocialIcons,
} from '../styles';
import { InputWrapper, SubmitStateText, SuccessText } from './styles';

export default function MailingListSignup() {
  const [submitState, setSubmitState] = useState<null | 'success'>(null);

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values, actions) => {
        const cleanEmail = values.email.trim();

        if (cleanEmail) {
          await axios({
            method: 'post',
            data: {
              email: cleanEmail,
            },
            url: '/api/iterable/subscribe',
          });

          actions.setFieldValue('email', '');
          setSubmitState('success');
        }
      }}
    >
      <Form>
        <InputRow>
          <InputWrapper>
            <Input
              placeholder="Your Email"
              variant="secondary"
              type="email"
              name="email"
              autoComplete="email"
            />
            <SubmitStateText>
              {submitState === 'success' ? (
                <SuccessText>Thanks for signing up!</SuccessText>
              ) : (
                <>
                  By subscribing, you agree with our{' '}
                  <SiteLink href="/about/terms-and-conditions">
                    Terms & Conditions
                  </SiteLink>{' '}
                  and{' '}
                  <SiteLink href="/about/privacy-policy">
                    Privacy Policy
                  </SiteLink>
                </>
              )}
            </SubmitStateText>
          </InputWrapper>
          <DesktopButton>
            <Button
              colorScheme="white"
              type="submit"
              variant="secondary"
              name="subscribe"
            >
              Sign Me Up
            </Button>
          </DesktopButton>
        </InputRow>
        <LowerRow>
          <MobileButton>
            <Button
              colorScheme="white"
              type="submit"
              variant="secondary"
              name="subscribe"
            >
              Sign Me Up
            </Button>
          </MobileButton>
          <SocialIcons>
            <a
              href="https://twitter.com/wearesproutl"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Twitter"
            >
              <Twitter />
            </a>
            <a
              href="https://www.instagram.com/wearesproutl/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Instagram"
            >
              <Instagram />
            </a>
          </SocialIcons>
        </LowerRow>
      </Form>
    </Formik>
  );
}
