import SdkAuth, { TokenProvider } from '@commercetools/sdk-auth';

let existingToken: any;

/**
 * This token should be used for public, read-only queries such as for product information.
 * It is safe to use on both the client and server and is visitor-independent.
 * For user-stateful sessions, for instance to use with Baskets, create an Anonymous Token instead.
 * https://docs.commercetools.com/api/authorization#requesting-an-access-token-using-commercetools-oauth-20-server
 * @returns token
 */
export async function getBasicClientToken() {
  const authClient = new SdkAuth({
    host: 'https://auth.commercetools.com',
    projectKey: process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY,
    disableRefreshToken: false,
    credentials: {
      clientId: process.env.NEXT_PUBLIC_COMMERCETOOLS_BASIC_CLIENT_ID,
      clientSecret: process.env.NEXT_PUBLIC_COMMERCETOOLS_BASIC_CLIENT_SECRET,
    },
    scopes: [
      `view_products:${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
      `view_published_products:${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
    ],
  });

  const tokenProvider = new TokenProvider(
    {
      sdkAuth: authClient,
      fetchTokenInfo: (sdkAuth: any) => sdkAuth.clientCredentialsFlow(),
      // onTokenInfoChanged: () => console.log('Token changed!'),
    },
    existingToken,
  );

  const token = await tokenProvider.getTokenInfo();

  existingToken = token;

  return token;
}

/**
 * Get just an access token to be used in Authorization request headers
 * @returns {string} Access token
 */
export async function getBasicClientAccessToken() {
  const token = await getBasicClientToken();

  return token.access_token;
}
