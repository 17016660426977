import styled from 'styled-components';
import theme from 'utils/theme';
import { tablet } from 'utils/media';
import { Heading6 } from 'components/text';
import Container from 'components/layout/Container';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 999;
  background-color: ${theme.colors.white};
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
`;

export const Title = styled(Heading6)`
  color: ${theme.colors.purple};
  margin: 0px;
`;

export const Text = styled.p`
  margin: 0px 0px 10px;

  a {
    text-decoration: underline;
  }

  ${tablet} {
    margin: 0px;
  }
`;

export const StyledContainer = styled(Container)`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 900px;
`;

export const CtaContainer = styled.div`
  align-self: center;
`;

export const CloseButton = styled.button`
  padding: 0px;
  border: 0px;
  margin: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
  border-radius: 100%;
  zindex: 10;
  cursor: pointer;
`;
